export enum QueryKeys {
  // Auth
  AUTH_USER_CREDS = 'get_user_credentials',

  // Business related
  BUSINESS = 'business',
  BUSINESS_BALANCE = 'business_balance',
  BUSINESS_BALANCE_TRANSACTIONS = 'business_balance_transactions',
  BUSINESS_CONSUMERS = 'business_consumers',
  BUSINESS_COUPONS = 'business_coupons',
  BUSINESS_GIFT_CARDS = 'business_gift_cards',
  BUSINESS_LOCATIONS = 'business_locations',
  BUSINESS_LOCATIONS_OVERVIEW = 'business_locations_overview',
  BUSINESS_ORDERS = 'business_orders',
  BUSINESS_RECURRING_ORDERS = 'business_recurring_orders',
  BUSINESS_PLANS = 'business_plans',
  BUSINESS_PRODUCTS = 'business_products',
  BUSINESS_SETTINGS = 'business_settings',
  BUSINESS_METADATA = 'business_website',
  BUSINESS_TAX_RATES = 'business_tax_rates',
  BUSINESS_SUBSCRIPTION_INVOICE = 'business_subscription_invoice',

  // Balance Transaction
  EXPORT_BALANCE_TRANSACTIONS = 'export_balance_transactions',

  // Consumer
  CONSUMER = 'consumer',
  CONSUMER_TRANSACTIONS = 'consumer_transactions',
  CONSUMER_REFERRALS = 'consumer_referrals',
  CONSUMER_SUBSCRIPTIONS = 'consumer_subscriptions',

  // Credit
  CREDIT = 'credit',

  PRODUCER = 'producer',
  PRODUCER_ACCOUNT = 'producer_account',
  PRODUCER_BUSINESS = 'producer_business',
  PRODUCER_CREDIT = 'producer_credit',
  PRODUCER_INVOICE_HISTORY = 'producer_invoice_history',

  // Coupon
  COUPON = 'coupon',
  CREATE_ACCOUNT_LINK = 'create_account_link',
  COUPON_ORDERS = 'coupon_orders',
  COUPON_LOCATIONS = 'coupon_locations',

  // Gift Card
  GIFT_CARD = 'gift_card',

  // Job
  JOB_PACKING_SLIPS = 'job_packing_slips',

  // Location
  LOCATION = 'location',
  LOCATION_COOKING_REPORT = 'location_cooking_report',
  LOCATION_COUPONS = 'location_coupons',
  LOCATION_DELIVERIES = 'location_deliveries',
  LOCATION_DELIVERY_REPORT = 'location_delivery_report',
  LOCATION_END_OF_DAY_REPORT = 'location_end_of_day_report',
  LOCATION_ORDERS = 'location_orders',
  LOCATION_UNFULFILLED_ORDERS = 'location_unfulfilled_orders',
  LOCATION_PICKUP_ADDRESSES = 'location_pickup_addresses',
  LOCATION_PLANS = 'location_plans',
  LOCATION_RECURRING_ORDERS = 'location_recurring_orders',
  LOCATION_FULFILLMENT = 'location_fulfillment',
  LOCATION_SCHEDULES = 'location_schedules',
  LOCATION_SCHEDULES_TILES = 'location_schedules_tiles',
  LOCATION_SCHEDULE_CALENDAR = 'location_schedules_calendar',

  // Metric
  ALL_METRICS = 'all_metrics',
  REVENUE_METRICS = 'revenue_metrics',
  COUNT_METRICS = 'count_metrics',
  CANCELLATION_METRICS = 'cancellation_metrics',

  // Offer
  OFFER = 'offer',

  // Order
  ORDER = 'order',
  ORDER_REFUNDS = 'order_refunds',
  EXPORT_ORDERS = 'export_orders',

  // Package Slips
  PACKAGE_SLIPS = 'package_slips',

  // Pickup Address
  PICKUP_ADDRESS = 'pickup_address',

  // Plan
  PLAN = 'plan',
  PLAN_IMAGES = 'plan_images',
  PLAN_REVENUE = 'plan_revenue',
  PLAN_PRODUCTS = 'plan_products',
  PLAN_SUBSCRIPTIONS = 'plan_subscriptions',
  PLAN_STATISTIC = 'plan_statistic',

  // Product
  PRODUCT = 'product',
  ALL_PRODUCT_ALLERGENS = 'product_allergens',
  ALL_PRODUCT_CATEGORIES = 'product_categories',
  ALL_PRODUCT_TAGS = 'product_tags',
  PRODUCT_IMAGES = 'product_images',

  // Producer Card
  PRODUCER_CARD = 'producer_card',

  // RecurringOrder
  RECURRING_ORDER = 'recurring_order',
  EXPORT_RECURRING_ORDERS = 'export_recurring_order',

  // Refund
  REFUND = 'refund',

  //  Schedule
  SCHEDULE = 'schedule',

  // Subscription
  SUBSCRIPTION = 'subscription',

  // Subscription Invoice
  SUBSCRIPTION_INVOICE = 'subscription_invoice',
  SUBSCRIPTION_INVOICE_REFUNDS = 'subscription_invoice_refunds',
  EXPORT_SUBSCRIPTION_INVOICE = 'export_subscription_invoice',
}

/** FIXME: This provides no type safety at all. */
export interface QueryFnProps<InputType, FilterType = void> {
  queryKey: [QueryKeys, InputType, FilterType?] | any;
}
